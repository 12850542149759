import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { t } from "i18next";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export class FormatDateTime {
    static registerLocale(): void {
        dayjs.extend(calendar);
        dayjs.extend(relativeTime);
        dayjs.extend(isToday);
        dayjs.extend(updateLocale);
        dayjs.extend(localizedFormat);
        dayjs.extend(duration);

        const relativeTimeFormatting = {
            past: `%s ${t('ago')}`,
            s: `${t('a few seconds')}`,
            m: `${t('a minute')}`,
            mm: `%d ${t('minutes')}`,
            h: `${t('an hour')}`,
            hh: `%d ${t('hours')}`
        };
        const calenderFormat = {
            lastDay: `[${t('Yesterday at')}] LT`,
            lastWeek: `[${t('Last')}] dddd [${('at')}] LT`,
            sameElse: 'LL'
        };

        dayjs.updateLocale('en', {
            calendar: calenderFormat,
            relativeTime: relativeTimeFormatting,
            formats: {
                L: 'MM/DD/YYYY'
            }
        });
    }
    public static convert(dateTime: Date): string {
        this.registerLocale();
        if (dayjs(dateTime).isToday())
            return dayjs(dateTime).fromNow();
        else
            return dayjs(dateTime).calendar(dayjs());
    }
    public static getDateString(dateString: string): string {
        return dayjs(dateString).format('L');
    }
    public static getDurationFromString(duration: string): string {
        const parsedDuration = dayjs.duration(duration);
        let parsedString = '';
        if (parsedDuration.hours() > 0)
            parsedString += `${parsedDuration.hours()} ${t('hours')} `;
        if (parsedDuration.minutes() > 0)
            parsedString += `${parsedDuration.minutes()} ${t('minutes')} `;
        if (parsedDuration.seconds() > 0)
            parsedString += `${parsedDuration.seconds()} ${t('seconds')}`;
        return parsedString;
    }
    public static getDuration(startTime: Date, endTime: Date): string {
        const start = dayjs(startTime);
        const end = dayjs(endTime);
        const diff = end.diff(start);
        const durationObj = dayjs.duration(diff);

        const hours = durationObj.hours();
        const minutes = durationObj.minutes();
        const seconds = durationObj.seconds();

        let durationString = '';
        if (hours > 0) durationString += `${hours} ${t('hours')} `;
        if (minutes > 0) durationString += `${minutes} ${t('minutes')} `;
        if (seconds > 0) durationString += `${seconds} ${t('seconds')}`;

        return durationString.trim();
    }
}