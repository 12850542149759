import { FC, useCallback, useEffect, useState } from "react";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";
import { CheckboxVisibility, ConstrainMode, DetailsListLayoutMode, DetailsRow, IColumn, IconButton, IDetailsRowProps, IRenderFunction, ISelection, Selection, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { useTeams } from "../../context/TeamsContext";
import { IMessage } from "../../model/model";
import { Constants } from "../../helpers/Constants";
import { FormatDateTime } from "../../helpers/FormatDateTime";
import ExpandableRow from "./ExpandableRow";
import { useMessages } from "../../context/MessagesContext";

interface IMeetingRecordingListProps extends ICommonProps {
    selectedMeetings?: IMessage[];
    setSelectedMeetings?: (data: IMessage[]) => void;
    // use only for testing purposes
    skipViewportMeasures?: boolean;
}
const MeetingRecordingList: FC<IMeetingRecordingListProps> = (props) => {
    const { selectedMeetings, setSelectedMeetings, skipViewportMeasures } = props;
    const { teamsState } = useTeams();
    const { messageState } = useMessages();
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const onRenderCreatedDateTime = useCallback((item: IMessage) => {
        return <span>{FormatDateTime.convert(item.createdDateTime as Date)}</span>;
    }, []);

    const onRenderName = (item: IMessage) => {
        return <span>{messageState.meetingState.meeting.topic}</span>;
    };

    const onRenderLength = useCallback((item: IMessage) => {
        if (item.eventDetail?.meetingTranscriptAndAttendanceReport?.attendanceReport) {
            return <span>{FormatDateTime.getDuration(item.eventDetail?.meetingTranscriptAndAttendanceReport?.attendanceReport.meetingStartDateTime, item.eventDetail?.meetingTranscriptAndAttendanceReport?.attendanceReport.meetingEndDateTime)}</span>;
        }
        const foundEvent = messageState.meetingState.messages.find((message) => {
            if (message.eventDetail)
                return message.eventDetail?.callId === item.eventDetail?.callId && message.eventDetail['@odata.type'] === Constants.EventDetail.CallEndedEventMessageDetail;
            return false;
        });
        if (foundEvent) {
            return <span>{FormatDateTime.getDurationFromString(foundEvent.eventDetail?.callDuration as string)}</span>;
        }
    }, [messageState.meetingState.messages]);

    const toggleRowExpansion = useCallback((id: string) => {
        if (expandedRows.includes(id)) {
            setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
        } else {
            setExpandedRows([...expandedRows, id]);
        }
    }, [expandedRows]);

    const onRenderExpand = useCallback((item: IMessage) => {
        return <IconButton
            iconProps={{ iconName: expandedRows.includes(item.Id as string) ? 'ChevronUp' : 'ChevronDown' }}
            data-testid="expandColumn"
            onClick={() => toggleRowExpansion(item.Id as string)}
        />;
    }, [expandedRows, toggleRowExpansion]);

    const [selection] = useState<ISelection<IMessage>>(() => new Selection<IMessage>({
        onSelectionChanged: () => {
            if (setSelectedMeetings)
                setSelectedMeetings(selection.getSelection());
        },
        items: selectedMeetings ?? [],
        getKey: (item: IMessage) => item.Id as string
    }));

    const columns: IColumn[] = [
        { key: 'createdDateTime', name: t('Created Date'), minWidth: 80, maxWidth: 180, onRender: onRenderCreatedDateTime },
        { key: 'name', name: t('Name'), minWidth: 120, maxWidth: 500, onRender: onRenderName },
        { key: 'length', name: t('Length'), minWidth: 200, onRender: onRenderLength },
        { key: 'expandColumn', name: '', minWidth: 50, maxWidth: 50, onRender: onRenderExpand }
    ];

    const getEventDetails = useCallback(() => {
        if (messageState.meetingState.isLoading) return;

        const messages = [...messageState.meetingState.messages];
        const callRecordings = getCallRecordingEventMessageDetail(messages);
        if (callRecordings.length === 0) {
            setMessages([{ MessageType: Constants.MeetingRecordingNotAvailable }]);
        }
        else
            setMessages(callRecordings);
    }, [messageState.meetingState.isLoading, messageState.meetingState.messages]);

    useEffect(() => {
        if (selectedMeetings?.length === 0) {
            selection.setAllSelected(false);
        }
        getEventDetails();
    }, [getEventDetails, selectedMeetings?.length, selection]);

    const onRenderRow: IRenderFunction<IDetailsRowProps> = useCallback((rowProps, defaultRender) => {
        if (!rowProps || defaultRender === undefined) return null;

        const message = rowProps.item as IMessage;
        const isRowExpanded = expandedRows.includes(message.Id as string);
        return (
            <>
                <DetailsRow {...rowProps} />
                {isRowExpanded && (
                    <ExpandableRow event={messageState.meetingState.event} message={message} />
                )}
            </>
        );
    }, [expandedRows, messageState.meetingState.event]);

    return <ShimmeredDetailsList key={teamsState.userContext?.chat?.id}
        setKey={teamsState.userContext?.chat?.id}
        items={messages}
        columns={columns}
        selection={selection}
        checkboxVisibility={CheckboxVisibility.always}
        selectionMode={SelectionMode.multiple}
        enableShimmer={messageState.meetingState.isLoading}
        layoutMode={DetailsListLayoutMode.justified}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Select row"
        ariaLabelForShimmer={t('Loading...')}
        selectionPreservedOnEmptyClick={true}
        constrainMode={ConstrainMode.unconstrained}
        onRenderRow={onRenderRow}
        skipViewportMeasures={skipViewportMeasures}
    />;
};

function getCallRecordingEventMessageDetail(messages: IMessage[]) {
    return messages.filter((message) => {
        if (message.eventDetail) {
            return (message.eventDetail['@odata.type'] === Constants.EventDetail.CallRecordingEventMessageDetail
                && message.eventDetail.callRecordingStatus === Constants.EventDetail.CallRecordingStatusSuccess);
        }
        return false;
    });
}

export default MeetingRecordingList;