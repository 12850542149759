import { FC, useCallback } from "react";
import { IEvent, IMeetingAttachment } from "../../model/model";
import { FileType } from "../../helpers/FileType";
import { CheckboxVisibility, ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn } from "@fluentui/react";
import { t } from "i18next";
import { FormatDateTime } from "../../helpers/FormatDateTime";

interface IMeetingAttachmentProps {
    event: IEvent;
    skipViewportMeasures?: boolean;
}

const MeetingAttachment: FC<IMeetingAttachmentProps> = (props) => {

    const onRenderCreatedDateTime = useCallback((item: IMeetingAttachment) => {
        return <span>{FormatDateTime.getDateString(item.lastModifiedDateTime)}</span>;
    }, []);

    const onRenderName = (item: IMeetingAttachment) => {
        return <span>{item.name}</span>;
    };

    const onRenderSize = (item: IMeetingAttachment) => {
        return <span>{FileType.bytesToMB(parseInt(item.size))}</span>;
    };

    const onRenderIcon = (item: IMeetingAttachment) => {
        const fileType = FileType.getfileType(item.name);
        return <span><img src={fileType.url} alt={fileType.docType} width={16} height={16} /></span>;
    };

    const columns: IColumn[] = [
        { key: 'fileIcon', name: '', minWidth: 20, maxWidth: 20, onRender: onRenderIcon },
        { key: 'name', name: t('Name'), minWidth: 120, maxWidth: 500, onRender: onRenderName },
        { key: 'size', name: t('Size'), minWidth: 120, onRender: onRenderSize },
        { key: 'createdDateTime', name: t('Date/Time'), minWidth: 80, maxWidth: 180, onRender: onRenderCreatedDateTime }
    ];

    return <DetailsList
        items={props.event.attachments}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        layoutMode={DetailsListLayoutMode.justified}
        ariaLabelForSelectionColumn={t("Toggle selection")}
        ariaLabelForSelectAllCheckbox={t("Toggle selection for all items")}
        checkButtonAriaLabel={t("Select row")}
        selectionPreservedOnEmptyClick={true}
        constrainMode={ConstrainMode.unconstrained}
        skipViewportMeasures={props.skipViewportMeasures}
    />;
};

export default MeetingAttachment;