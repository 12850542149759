import { FC } from 'react';
import { archiveStatusStyleLeft, archiveStatusStyleRight, iconClass, tooltipHostStyles } from '../../styles/MergeStyleSets';
import { Shimmer, Stack, TooltipHost } from '@fluentui/react';
import { IFileListItem, IProgressItem } from '../../model/model';
import { useId } from '@fluentui/react-hooks';
import { t } from "i18next";
import AllArchivedIcon from '../../images/all-archived.svg';
import PartiallyArchivedIcon from '../../images/partially-archived.svg';
import NotArchivedIcon from '../../images/not-archived.svg';
import ErrorIcon from '../../images/error.svg';
import { Features } from '../../config/Features';
import SyncConflictControl from '../common/SyncConflictControl';
import { ICommonProps } from '../ICommonProps';

interface IArchiveStatusProps extends ICommonProps {
    item: IFileListItem,
    progress: IProgressItem
}

const ArchiveStatus: FC<IArchiveStatusProps> = (props) => {
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    const { dataProvider, item, progress } = props;

    if (Features.UseNewLoading) {
        if (!item.archivingStatusChecked && progress.loading) {
            return <Shimmer aria-label={t('Loading...')} />;
        }
    } else {
        const isFileConnected = item.documentConnection ?? item.isConnectedTo360;
        if (progress.loading &&
            (item.isFolder || !isFileConnected)) {
            return <span data-testid="archiveStatusLoading"></span>;
        }
    }

    const { toolTipIcon, toolTipContent } = getTooltipInfo(item);
    return <Stack horizontal horizontalAlign="start">
        <span style={archiveStatusStyleRight}>
            <TooltipHost content={toolTipContent} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
                <img src={toolTipIcon} className={iconClass} alt={toolTipContent} />
            </TooltipHost>
        </span>
        <span style={archiveStatusStyleLeft}>
            {item.isFolder && `${item.archiveCount} (${item.totalCount}) `}
            {item.isSyncFailed && Features.ResolveSyncConflict && <SyncConflictControl item={item} style={{ padding: '0px 6px', height: '22px', width: 'auto' }} dataProvider={dataProvider} />}
        </span>
    </Stack>;
};

export default ArchiveStatus;

function getTooltipInfo(item: IFileListItem) {
    return item.isFolder ? getFolderTooltipInfo(item) : getFileTooltipInfo(item);
}

function getFolderTooltipInfo(item: IFileListItem) {
    if (item.archiveCount as number === 0) {
        return {
            toolTipIcon: NotArchivedIcon,
            toolTipContent: t('None of the files in the folder are archived')
        };
    }

    if (item.archiveCount === item.totalCount) {
        return {
            toolTipIcon: AllArchivedIcon,
            toolTipContent: t('All the files in the folder are archived')
        };
    }

    return {
        toolTipIcon: PartiallyArchivedIcon,
        toolTipContent: t('The files in the folder are partially archived')
    };
}

function getFileTooltipInfo(item: IFileListItem) {
    if (!item.documentConnection && !item.isConnectedTo360) {
        return {
            toolTipIcon: NotArchivedIcon,
            toolTipContent: t('Not archived in 360°')
        };
    }

    if (item.isSyncFailed) {
        return {
            toolTipIcon: ErrorIcon,
            toolTipContent: t('Synchronization conflict')
        };
    }

    return {
        toolTipIcon: AllArchivedIcon,
        toolTipContent: t('Archived in 360°')
    };
}