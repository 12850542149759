import { FC, useCallback, useState } from "react";
import { Features } from "../../config/Features";
import { classNames } from "../../styles/MergeStyleSets";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";
import { IPivotItemProps, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, ThemeProvider } from "@fluentui/react";
import MeetingChat from "./MeetingChat";
import MeetingRecordingList from "./MeetingRecordingList";
import { useBoolean } from "@fluentui/react-hooks";
import { IArchiveMeetingResult, IMessage } from "../../model/model";
import MeetingArchivePanel from "./panel/MeetingArchivePanel";
import { useMessages } from "../../context/MessagesContext";

const MeetingArchiving: FC<ICommonProps> = (props) => {
    const { messageState } = useMessages();
    const [isArchivePanelOpen, { setTrue: openArchivePanel, setFalse: closeArchivePanel }] = useBoolean(false);
    const [selectedMeetings, setSelectedMeetings] = useState<IMessage[]>([]);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [selectedPivotTab, setSelectedPivotTab] = useState<{ props: IPivotItemProps } | undefined>(undefined);

    const onArchiveHandler = useCallback(() => {
        openArchivePanel();
    }, [openArchivePanel]);

    const onSaveComplete = useCallback((response: IArchiveMeetingResult) => {
        setSelectedMeetings([]);
        if (response.DocumentNumber)
            setSuccessMessage(`${t('Meetings archived successfully on Document')} ${response.DocumentNumber}.`);
    }, []);

    const onSuccessMessageDismiss = useCallback(() => {
        setSuccessMessage('');
    }, []);

    const isMeetingTabSelected = useCallback(() => {
        return selectedPivotTab?.props.headerText === t('Archive Recording');
    }, [selectedPivotTab?.props.headerText]);

    if (Features.EnableMeetingArchiving) {
        return <ThemeProvider className={classNames.wrapper}>
            {
                messageState.meetingState.meeting.Error &&
                <MessageBar messageBarType={MessageBarType.error}>{messageState.meetingState.meeting.Error.Message || messageState.meetingState.meeting.Error.Code}</MessageBar>
            }
            {successMessage &&
                <MessageBar messageBarType={MessageBarType.success} isMultiline={false} onDismiss={onSuccessMessageDismiss} dismissButtonAriaLabel="Close">
                    {successMessage}
                </MessageBar>
            }
            <Pivot onLinkClick={setSelectedPivotTab}>
                <PivotItem headerText={t('Archive Chat')}>
                    <MeetingChat {...props} setSelectedMeetings={setSelectedMeetings} selectedMeetings={selectedMeetings} />
                </PivotItem>
                <PivotItem headerText={t('Archive Recording')}>
                    <MeetingRecordingList {...props} setSelectedMeetings={setSelectedMeetings} selectedMeetings={selectedMeetings} />
                </PivotItem>
            </Pivot>
            {
                selectedMeetings.length > 0 && <PrimaryButton className={classNames.meetingArchivingArchiveButtonStyle} text={t('Archive')} onClick={onArchiveHandler} />
            }
            <MeetingArchivePanel {...props} showMeetingSelection={isMeetingTabSelected()} onSaveComplete={onSaveComplete} isOpen={isArchivePanelOpen} onDismiss={closeArchivePanel} selectedMeetings={selectedMeetings} />
        </ThemeProvider>;
    } else {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{t('Meeting Archiving is not supported in this version of 360°')}</h3>
        </blockquote>;
    }
};

export default MeetingArchiving;