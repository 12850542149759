import { FC, useCallback, useState } from "react";
import { IDropdownOption, List } from "@fluentui/react";
import Accordion from "../../common/Accordian";
import { IMessage, ISelectedMeetingData } from "../../../model/model";
import { useMessages } from "../../../context/MessagesContext";
import { FormatDateTime } from "../../../helpers/FormatDateTime";
import { t } from "i18next";
import { MessagesActionType } from "../../../context/MessagesReducer";

interface IMeetingSelectionViewProps {
    selectedMeetings?: IMessage[];
}

const getTrueKeys = (selectedItems: { [key: string]: boolean }): string[] => {
    return Object.keys(selectedItems).filter(key => selectedItems[key]);
};

const MeetingSelectionView: FC<IMeetingSelectionViewProps> = (props) => {
    const { messageState, dispatch } = useMessages();
    const [selectedMeetings, setSelectedMeetings] = useState<ISelectedMeetingData[]>([]);

    const selectContentOptions: IDropdownOption[] = [
        { key: 'recordings', text: t('Recordings') },
        { key: 'transcripts', text: t('Transcripts') },
        { key: 'attendees', text: t('Attendees') }
    ];

    const updateMeetingSelection = useCallback((meeting: ISelectedMeetingData, id: string, title: string, selectedItems: { [key: string]: boolean }) => {
        if (meeting.id === id) {
            if (Object.values(selectedItems).every(value => value === false)) {
                return { id: "", title: "", selectedItems: [] }; // Mark for removal
            }

            const keyWithTrueValues = getTrueKeys(selectedItems);
            const isSelectionSame = meeting.selectedItems.length === keyWithTrueValues.length
                && meeting.selectedItems.every((value, index) => value === keyWithTrueValues[index]);
            if (!isSelectionSame) {
                return { ...meeting, selectedItems: keyWithTrueValues };
            }
        }
        return meeting;
    }, []);

    const onAccordianSelectionChange = useCallback((id: string, title: string, selectedItems: { [key: string]: boolean }) => {
        let updateSelectedMeetings = [...selectedMeetings];

        if (!updateSelectedMeetings.some(m => m.id === id)) {
            updateSelectedMeetings = [...updateSelectedMeetings, { id, title, selectedItems: getTrueKeys(selectedItems) }];
        }
        updateSelectedMeetings = updateSelectedMeetings.map(meeting => updateMeetingSelection(meeting, id, title, selectedItems)).filter(meeting => meeting?.id);
        setSelectedMeetings(updateSelectedMeetings);
        dispatch({ type: MessagesActionType.SelectedMeetings, payload: { selectedMeetings: updateSelectedMeetings } });
    }, [dispatch, selectedMeetings, updateMeetingSelection]);

    const accordianTitle = (item: IMessage) => {
        return `${FormatDateTime.convert(item.createdDateTime as Date)}, ${messageState.meetingState.meeting.topic}`;
    };

    const accordianItems = () => {
        const items = selectContentOptions.map((s) => s.text);

        //Creates an array of key-value pairs where each item is paired with false 
        //and converts this array of key-value pairs into an object where each key is an item and each value is false
        const updated = Object.fromEntries(items.map(item => [item, false]));
        return updated;
    };

    const onRenderSelectedMeetings = useCallback((data?: ISelectedMeetingData, index?: number, isScrolling?: boolean) => {
        return <div style={{ marginTop: '10px' }}>
            <div><strong>{data?.title}</strong></div>
            <div>{data?.selectedItems.map((item, index) => (
                <span key={`${data?.id}_${index}`} data-testid={`${data?.id}_${index}`}>{item}
                    {data?.selectedItems.length - 1 === index ? '' : ','} </span>
            ))}</div>
        </div>;
    }, []);

    return <div style={{ margin: '20px 0px 0px -12px' }}>
        <div style={{ margin: '20px 0 0 0', maxHeight: '50vh', overflow: 'auto' }}>
            {props.selectedMeetings?.map((item, index) => (
                <div key={item.Id as string} style={{ marginBottom: '5px' }}>
                    <Accordion id={item.Id as string} title={accordianTitle(item)}
                        items={accordianItems()} width={370} onSelectionChange={onAccordianSelectionChange} />
                </div>
            ))}
        </div>
        <div>
            <h3>{t('Selected meetings')} ({selectedMeetings.length})</h3>
            <List items={selectedMeetings} onRenderCell={onRenderSelectedMeetings} />
        </div>
    </div>;
};

export default MeetingSelectionView;